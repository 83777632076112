<template>
  <el-container v-if="info.id">
    <el-main class="left">
      <div class="order-info">
        <div class="title">订单信息</div>
        <div class="content">
          <ul class="order-num">
            <li>
              订单编号：
              <span>{{ info.pay_sn }}</span>
            </li>
            <li>
              处理时间：
              <span>{{ info.create_time }}</span>
            </li>
            <li>
              订单来源：
              <span>{{ info.from == 1 ? '微信' : info.from == 2 ? '支付宝' : '快应用' }}</span>
            </li>
          </ul>
          <div class="handle-progress" v-if="progress_status1">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">订单关闭</p>
              <p class="handle-time">{{ info.cancel_time }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status2">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">在线支付</p>
              <p class="handle-time">{{ info.payment_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">订单关闭</p>
              <p class="handle-time">{{ info.cancel_time }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status3">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">在线支付</p>
              <p class="handle-time">{{ info.payment_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">商品发货</p>
              <p class="handle-time">{{ getDateformat(info.extend_order_extend.tracking_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">4</div>
              <p class="handle-text" style="color: #409eff">订单关闭</p>
              <p class="handle-time">{{ info.cancel_time }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="progress_status4">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交订单</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">2</div>
              <p class="handle-text" style="color: #409eff">在线支付</p>
              <p class="handle-time">{{ info.payment_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">3</div>
              <p class="handle-text" style="color: #409eff">商品发货</p>
              <p class="handle-time">{{ getDateformat(info.extend_order_extend.tracking_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">4</div>
              <p class="handle-text" style="color: #409eff">确认收货</p>
              <p class="handle-time">{{ getDateformat(info.finnshed_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">5</div>
              <p class="handle-text" style="color: #409eff">订单关闭</p>
              <p class="handle-time">{{ info.cancel_time }}</p>
            </div>
          </div>
          <div class="handle-progress" v-if="!progress_status1 && !progress_status2 && !progress_status3 && !progress_status4">
            <div class="item" style="background: #ddddfd">
              <div class="num" style="background: #409eff; color: #fff">1</div>
              <p class="handle-text" style="color: #409eff">提交时间</p>
              <p class="handle-time">{{ info.create_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
              <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">在线支付</p>
              <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.extend_order_extend.tracking_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.extend_order_extend.tracking_time ? '#409EFF' : '', color: info.extend_order_extend.tracking_time ? '#fff' : '' }">3</div>
              <p class="handle-text" :style="{ color: info.extend_order_extend.tracking_time ? '#409EFF' : '' }">商品发货</p>
              <p class="handle-time" v-if="info.extend_order_extend.tracking_time">{{ getDateformat(info.extend_order_extend.tracking_time) }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.finnshed_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.finnshed_time ? '#409EFF' : '', color: info.finnshed_time ? '#fff' : '' }">4</div>
              <p class="handle-text" :style="{ color: info.finnshed_time ? '#409EFF' : '' }">确认收货</p>
              <p class="handle-time" v-if="info.finnshed_time">{{ info.finnshed_time }}</p>
            </div>
            <div class="line"></div>
            <div class="item" :style="{ background: info.finnshed_time ? '#ddddfd' : '' }">
              <div class="num" :style="{ background: info.finnshed_time ? '#409EFF' : '', color: info.finnshed_time ? '#fff' : '' }">5</div>
              <p class="handle-text" :style="{ color: info.finnshed_time ? '#409EFF' : '' }">订单完成</p>
              <p class="handle-time" v-if="info.finnshed_time">{{ info.finnshed_time }}</p>
            </div>
          </div>
          <div class="handle-status">
            <div class="status">订单状态： {{ info.state == 0 ? '已关闭' : info.state == 10 ? '待支付' : info.state == 20 ? '待发货' : info.state == 30 ? '待收货' : '已完成' }}</div>
            <div class="btn" v-if="info.state == 30">
              <el-button type="primary" @click="confirmReceipt">确认收货</el-button>
            </div>
            <div class="btn" v-if="info.state == 20">
              <el-button type="primary" @click="showOrderSend = !0">发货</el-button>
            </div>
            <div class="order-actions" v-if="info.state > 0">
              <el-button type="text" @click="print">打印订单</el-button>
              <el-button type="text" @click="showMessage = !0">备注</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="buyers-info">
        <div class="title">买家信息</div>
        <div class="content">
          <div>
            <p>
              买家：
              <span>{{ info.user_name || '- -' }}</span>
            </p>
            <p>
              买家备注：
              <span>{{ info.message || '- -' }}</span>
            </p>
          </div>
          <div>
            <p>
              收件人：
              <span>{{ info.extend_order_extend.reciver_infos.name }}</span>
            </p>
            <p>
              收件人电话：
              <span>{{ info.extend_order_extend.reciver_infos.phone }}</span>
            </p>
            <p>
              收件人地址：
              <span>{{ info.extend_order_extend.reciver_infos.address }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="goods-info">
        <div class="title">商品信息</div>
        <div class="content">
          <el-table :data="info.extend_order_goods" :header-cell-style="{ 'background-color': '#E8EEF1' }">
            <el-table-column prop="goods_img" label="商品信息" align="center" width="220px">
              <template slot-scope="scope">
                <div class="goods-title">
                  <el-image :src="scope.row.goods_img"></el-image>
                  <p>{{ scope.row.goods_title }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="sku_list.title" label="规格" align="center">
              <template slot-scope="scope">
                {{ scope.row.sku_list ? scope.row.sku_list.title : '- -' }}
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="单价" align="center"></el-table-column>
            <el-table-column prop="goods_num" label="数量" align="center"></el-table-column>
            <el-table-column prop="goods_pay_price" label="小计" align="center"></el-table-column>
          </el-table>
          <div class="price">
            <p>
              <span>商品总价：</span>
              <span>￥{{ info.amount }}</span>
            </p>
            <p>
              <span>运费：</span>
              <span>￥{{ info.freight_fee }}</span>
            </p>
            <p>
              <span>优惠券立减：</span>
              <span>￥{{ info.reduction_amount }}</span>
            </p>
            <p>
              <span>会员立减：</span>
              <span>￥{{ info.discount_reduction_amount }}</span>
            </p>
            <p>
              <span>会员余额立减：</span>
              <span>￥{{ info.pd_amount }}</span>
            </p>
          </div>
          <div class="total-price">
            <span>实付：</span>
            <span>￥{{ info.goods_amount }}</span>
          </div>
        </div>
      </div>
    </el-main>
    <el-main class="logistics-address">
      <div class="title">物流信息</div>
      <template v-if="logisticsInfo">
        <div class="sn">{{ company_name }}：{{ logisticsInfo.nu }}</div>
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
            {{ item.context }}
          </el-timeline-item>
        </el-timeline>
      </template>
      <template v-else>
        <No />
      </template>
    </el-main>
    <el-dialog title="备注" :visible.sync="showMessage" width="35%">
      <el-form v-if="info.id" label-width="85px">
        <el-form-item label="订单编号：">{{ info.pay_sn }}</el-form-item>
        <el-form-item label="总价：">{{ info.amount }}</el-form-item>
        <el-form-item label="收货人：">{{ info.extend_order_extend.reciver_infos.name }}</el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="info.system_message" type="textarea" :rows="8" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMessage = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmSetSystemMessage">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="发货" :visible.sync="showOrderSend" width="600px">
      <el-form label-width="90px" v-if="info.id">
        <el-form-item label="收货人：">{{ info.extend_order_extend.reciver_infos.name }}</el-form-item>
        <el-form-item label="收货地址：">{{ info.extend_order_extend.reciver_infos.combine_detail }}{{ info.extend_order_extend.reciver_infos.address }}</el-form-item>
        <el-form-item label="快递公司：">
          <el-select v-model="express_id" filterable placeholder="请选择">
            <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input v-model="tracking_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrderSend = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      id: '',
      info: {},
      goods_info: [],
      logisticsInfo: '',
      showMessage: !1,
      showOrderSend: !1,
      express_id: '', //快递公司
      tracking_no: '', //快递单号
      company_name: '',
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
    };
  },
  computed: {
    ...mapState({
      logisticsList: (state) => state.order.logisticsList, //物流公司列表
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.params.page);
    utils.rows = Number(this.$route.params.rows);
    utils.is_record = !0;
    this.id = this.$route.params.id;
    this.getOrderInfo();
    this.logisticsQuery();
  },
  methods: {
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
    comfirmSetSystemMessage() {
      this.$axios
        .post(this.$api.order.setSystemMessage, {
          id: this.id,
          system_message: this.info.system_message,
        })
        .then((res) => {
          if (res.code == 0) {
            this.showMessage = !1;
            this.$message({
              message: '备注成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发货
    confirmSend() {
      let orderInfo = this.info.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      }
      if (!this.tracking_no)
        return this.$message({
          message: '请填写快递单号',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.order.setSend, {
          id: this.id,
          need_express: 1,
          express_id: this.express_id,
          tracking_no: this.tracking_no,
        })
        .then((res) => {
          if (res.code == 0) {
            this.getOrderInfo();
            this.showOrderSend = !1;
            this.$message({
              message: '发货成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //确认收货
    confirmReceipt() {
      let orderInfo = this.info.extend_order_goods;
      for (let i in orderInfo) {
        if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可确认收货！');
      }
      this.$confirm(`请确认是否收货？`, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.order.confirmReceipt, { id: this.id }).then((res) => {
          if (res.code == 0) {
            this.getOrderInfo();
            this.$message({
              message: '订单收货成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //打印
    print() {
      let routeData = this.$router.resolve('/print/' + this.id);
      window.open(routeData.href, '_blank');
    },
    logisticsQuery() {
      this.$axios.post(this.$api.order.logisticsQuery, { id: this.id }).then((res) => {
        if (res.code == 0 && res.result.info.returnCode != '400') {
          this.company_name = res.result.company_name;
          this.logisticsInfo = res.result.info;
        }
      });
    },
    getOrderInfo() {
      this.$axios.post(this.$api.order.info, { id: this.id }).then((res) => {
        if (res.code == 0) {
          let info = res.result.info;
          let stateArr = [10];
          if (info.payment_time) stateArr.push(20);
          if (info.cancel_time) stateArr.push(0);
          if (info.extend_order_extend.tracking_time) stateArr.push(30);
          if (info.finnshed_time) stateArr.push(40);
          if (info.evaluate_time) stateArr.push(50);
          if ([0, 10].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
          if ([0, 10, 20].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
          if ([0, 10, 20, 30].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
          if ([0, 10, 20, 30, 40].sort().toString() == stateArr.sort().toString()) this.progress_status4 = 1;
          this.info = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
}
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 20px;
}
.left {
  margin-right: 8px;

  background: #fff;
  .order-info {
    margin-bottom: 30px;
  }
  .order-info .content {
    .order-num {
      display: flex;
      height: 47px;
      background: #f0f0f5;
      align-items: center;
      padding-left: 24px;
      li {
        font-weight: bold;
        font-size: 14px;
        margin-right: 40px;
        span {
          font-weight: 400;
          color: #17171a;
        }
      }
    }
    .handle-progress {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }

      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .handle-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
      .order-actions {
        margin-top: 10px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 30px;
    .content {
      margin-top: 24px;
      padding-left: 40px;
      div {
        font-weight: bold;
        display: flex;

        span {
          font-weight: 400;
        }
        p {
          width: 240px;
          &:last-child {
            width: auto;
          }
        }
        &:nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .goods-info .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #f5f5fa;
      color: #969699;
      span:last-child {
        color: #17171a;
        width: 80px;
        text-align: right;
      }
      p {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .goods-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 8px;
      }
      p {
        flex: 1;
      }
    }
    .total-price {
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      span:last-child {
        text-align: right;
        font-weight: 400;
        color: #fd5e94;
        width: 80px;
      }
    }
  }
}

.logistics-address {
  width: 480px;
  background: #fff;
  .sn {
    margin-bottom: 30px;
  }
  /deep/ .el-timeline-item__content {
    color: #17171a;
  }
}
</style>
